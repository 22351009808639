import styled from 'styled-components';
import Color from 'color';
import { CircularProgressProps } from './props';
import { getComputedColor } from '@starsoft/common/utils';

export const CircularProgress = styled.div<CircularProgressProps>`
  ${({ progress, pathColor, trailColor, invert }) => {
    const primaryColor =
      'var(--mui-palette-primary-main, var(--primary-color))';

    const trail = trailColor
      ? trailColor
      : invert
        ? getComputedColor(primaryColor)
        : Color(getComputedColor(primaryColor)).alpha(0.5).toString();

    const path = pathColor
      ? pathColor
      : invert
        ? Color(getComputedColor(primaryColor)).alpha(0.5).toString()
        : getComputedColor(primaryColor);

    return `
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background: conic-gradient(
        ${trail} ${progress}%, 0,
        ${path} ${100 - Math.ceil(progress)}%
      );
    `;
  }}
`;
