import Badge from './Badge';
import { RewardProps } from '../props';
import { memo } from 'react';
import { useRewardState } from '@/hooks/useRewardState';
import { Image } from '@starsoft/common/components';
import styles from './styles.module.scss';
import Link from '@/components/core/Link';
import { RewardId } from '@/enums/reward-id';

function RewardV1({ isLoading, reward }: RewardProps) {
  const isLuckyWheel = reward?.id === RewardId.LuckyWheel;

  const { isDisabled, getLink } = useRewardState(reward);

  if (isLoading) {
    return <div className={styles.wrapper__container__skeleton} />;
  }

  return (
    <div className={styles.wrapper}>
      {!isLuckyWheel && <Badge reward={reward} />}
      <Link
        className={`${styles.wrapper__container} ${isDisabled ? styles['wrapper__container--isDisabled'] : ''} ${!reward?.userReward ? styles['wrapper__container'] : ''} `}
        href={getLink()}
        shallow={true}
        scroll={false}
        style={{
          //@ts-ignore
          '--data-color': reward?.color,
        }}
      >
        <Image
          className={styles.wrapper__container__image}
          format="square"
          width={40}
          height={40}
          src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/icons/bonus/${reward?.image}`}
          alt={reward?.name}
        />
      </Link>
    </div>
  );
}

export default memo(RewardV1);
