import { useCallback, useEffect, useState } from 'react';
import { BadgeProps } from '../props';
import { Progress } from './styles';
import { UserRewardStatus } from '@/models/userReward/user-reward-status.enum';

export default function BadgeProgress({ reward }: BadgeProps) {
  const [progress, setProgress] = useState(0);

  const getProgress = useCallback(() => {
    if (!reward?.userReward) {
      return 100;
    }

    const now: number = +new Date();
    const isClaimable: boolean =
      reward.userReward.status === UserRewardStatus.Claimable;

    const endDate: number = +new Date(
      isClaimable ? reward.userReward.expireAt : reward.userReward.redeemableAt,
    );

    if (isClaimable) {
      const timeRemaining: number = endDate - now;
      const initialDuration: number =
        +new Date(reward.userReward.expireAt) - +new Date();
      const percentagePassed: number =
        ((initialDuration - timeRemaining) / initialDuration) * 100;

      return Math.min(100, percentagePassed);
    }

    const createdAt: number = +new Date(reward.userReward.createdAt);
    const totalDuration: number = endDate - createdAt;
    const elapsedTime: number = now - createdAt;
    const percentagePassed: number = (elapsedTime / totalDuration) * 100;

    return Math.min(100, percentagePassed);
  }, [reward]);

  function handleInterval() {
    setProgress(getProgress());

    const timer = setInterval(() => {
      setProgress(getProgress());
    }, 60 * 1000);

    return () => {
      clearInterval(timer);
    };
  }

  useEffect(handleInterval, [reward, getProgress]);

  return <Progress progress={progress} />;
}
