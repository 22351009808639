import { UserRewardStatus } from '@/models/userReward/user-reward-status.enum';
import { BadgeProps } from './props';
import { Container, Icon } from './styles';
import BadgeProgress from './Progress';
import { RewardId } from '@/enums/reward-id';

export default function Badge({ reward }: BadgeProps) {
  const isLevelUp: boolean = reward?.id === RewardId.LevelUp;

  if (!reward?.userReward) {
    return (
      <Container>
        <Icon className="fa-solid fa-lock" />
      </Container>
    );
  }

  if (
    !!reward?.userReward &&
    reward?.userReward?.status === UserRewardStatus.Pending &&
    !isLevelUp
  ) {
    return <BadgeProgress reward={reward} />;
  }

  return null;
}
